import { BuyerInfoType } from './BuyerInfo';
import { IHydraBase } from './interfaces/hydra';
import { SellerInfoType } from './SellerInfo';
import { WarehouseType } from './Warehouse';

interface ISimpleDim {
  [param: string]: string;
}

export interface IMeasurements {
  [param: string]: ISimpleDim;
}

export interface IItemDimensions {
  size?: ISimpleDim;
  measurements?: IMeasurements;
}

interface DeliveryCountryType extends IHydraBase {
  id: number;
  name: string;
}

export interface ProductType extends IHydraBase {
  id: number;
  warehouse: WarehouseType;
  relistParentId: number | null;
  price: number;
  images: string[];
  imageVersion: number;
  dimensions: string[];
  seller: SellerInfoType | null;
  buyer: BuyerInfoType | null;
  additionalItems: string[];
  segment: string;
  deliveryCountry: DeliveryCountryType;
  purchasePlace: string | null;
  purcahseDate: string | null;
  authLevel: number | null;
  type: string;
  hasPublicComments: boolean;
  isVintage: boolean;
  comment: string | null;
  name: string;
  description: string;
  condition: string;
  category: string;
  link: string;
  brand: string;
  color: string;
  material: string;
  pattern: string;
  model: string | null;
  invoice: string | null;
  packaging: string | null;
  serialNumber: string | null;
  agentComment: string | null;
  notForSaleReasons: string[];
  hallmarks: string | null;
  weight: string | null;
  isShippingInSameFiscalMarket: boolean | null;
}

export enum PRODUCT_TYPES {
  JEWELRY = 'jewelry',
  WATCH = 'watch',
}

export const PRODUCT_MATERIAL = {
  EN: {
    GOLD: 'gold',
    WHITE_GOLD: 'white gold',
    PINK_GOLD: 'pink gold',
    YELLOW_GOLD: 'yellow gold',
    SILVER: 'silver',
    PLATINUM: 'platinum',
    GOLD_STEEL: 'gold and steel',
  },
  FR: {
    GOLD: 'or',
    WHITE_GOLD: 'or blanc',
    PINK_GOLD: 'or rose',
    YELLOW_GOLD: 'or jaune',
    SILVER: 'argent',
    PLATINUM: 'platine',
    GOLD_STEEL: 'or et acier',
  },
};

export enum CommentBadge {
  Seller = 'Seller',
  Buyer = 'Buyer',
  Other = 'Other',
}

export interface ProductComment {
  description: string;
  customerEmail: string;
  langSource: string;
  createdAt: string;
  customerFirstName: string;
  customerLastName: string;
  commentBadge?: CommentBadge;
  commentAfterSale?: boolean;
}

export interface TranslateProductCommentType extends ProductComment {
  translation: string;
}
