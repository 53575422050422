import { datadogRum } from '@datadog/browser-rum';
import { FC, ReactNode, useEffect, useState } from 'react';
import { createContext, useContextSelector } from 'use-context-selector';

interface ModalState {
  modal: ModalInfoType;
  modalData: object | Array<any> | null;
}

interface ModalInfoType {
  modalType: string;
  modalInfo: { [key: string]: any };
}

interface ProductContextType extends ModalState {
  setModalData: (modalData: object | Array<any> | null) => void;
  setModalInfo: (props: ModalInfoType) => void;
}

const initialState: ModalState = {
  modal: {
    modalType: '',
    modalInfo: {},
  },
  modalData: null,
};

interface ModalProviderProps {
  children: ReactNode;
}

export const ModalContext = createContext<ProductContextType>(
  initialState as ProductContextType
);

export const ModalProvider: FC<ModalProviderProps> = ({ children }) => {
  const [modal, setModal] = useState<ModalInfoType>({
    modalType: '',
    modalInfo: {},
  });
  const setModalInfo = (data: ModalInfoType) => {
    setModal(data);

    // Modal will be closed when modalType is changed from a string to empty value
    if (!data.modalType && modal.modalType) {
      datadogRum.addAction(`Close modal ${modal.modalType}`, modal.modalInfo);
    }
  };
  const [modalData, setModalData] = useState<ModalState['modalData']>(null);
  const values = {
    modalData,
    setModalData,
    modal,
    setModalInfo,
  };

  useEffect(() => {
    if (modal.modalType) {
      datadogRum.addAction(`Open modal ${modal.modalType}`, modal.modalInfo);
    }
  }, [modal.modalType]);

  return (
    <ModalContext.Provider value={values}>{children}</ModalContext.Provider>
  );
};

export const useModal = () => {
  return {
    modalData: useContextSelector(ModalContext, (v) => v.modalData),
    modal: useContextSelector(ModalContext, (v) => v.modal),
    setModalData: useContextSelector(ModalContext, (v) => v.setModalData),
    setModalInfo: useContextSelector(ModalContext, (v) => v.setModalInfo),
  };
};
