import {
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  Radio,
  RadioGroup,
  Stack,
  Typography,
} from '@mui/material';
import Checkbox from '@mui/material/Checkbox';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useQualityControlTool } from '@/context/Qualitycontrol.context';
import AdditionalInfoSection from './AdditionalInfoSection';
import { ErrorType } from '../QcPanel/QcPanel';

interface RefurbishmentInfoProps {
  isViewMode: boolean;
  showWatchFunctioned: boolean;
  errors?: ErrorType[];
}

export default function RemarkInfo({
  isViewMode,
  showWatchFunctioned,
  errors,
}: RefurbishmentInfoProps) {
  const { t } = useTranslation();

  const { refurbishment, setRefurbishment, isWorkingWatch, setWorkingWatch } =
    useQualityControlTool();

  const watchValue = useMemo(() => {
    if (isWorkingWatch == null) return '';
    return isWorkingWatch ? 'true' : 'false';
  }, [isWorkingWatch]);

  const watchError = useMemo(() => {
    if (isWorkingWatch != null) return undefined;
    return errors && errors.find((e) => e.field === 'watch');
  }, [errors, isWorkingWatch]);

  const renderContent = () => {
    return (
      <Stack my={0.5} ml={1.5}>
        <FormControlLabel
          sx={{
            mt: -1.5,
            mx: 0,
            display: 'flex',
            flexDirection: 'row-reverse',
            justifyContent: 'flex-end',
          }}
          control={
            <Checkbox
              disabled={isViewMode}
              data-testid="refurbishment_checkbox"
              checked={refurbishment}
              onChange={(e) => {
                setRefurbishment(e.target.checked);
              }}
            />
          }
          label={
            <Typography variant="body1">
              {t('QC_V2.MAIN.REFURBISHMENT_DONE')}
            </Typography>
          }
        />

        {showWatchFunctioned && (
          <FormControl
            error={!!watchError}
            sx={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              flexWrap: 'wrap',
            }}
          >
            <FormLabel
              sx={{
                mr: 2,
                '&.Mui-focused': {
                  color: (theme) => theme.palette.common.black,
                },
              }}
            >
              {t('QC_V2.MAIN.DOES_WATCH_FUNCTION')}
            </FormLabel>

            <RadioGroup
              row
              name="controlled-radio-buttons-group"
              value={watchValue}
              onChange={(e) => setWorkingWatch(e.target.value === 'true')}
            >
              <FormControlLabel
                value="true"
                disabled={isViewMode}
                control={<Radio />}
                label={t('QC_V2.MAIN.YES')}
              />
              <FormControlLabel
                value="false"
                disabled={isViewMode}
                control={<Radio />}
                label={t('QC_V2.MAIN.NO')}
              />
            </RadioGroup>

            {!!watchError?.message && (
              <FormHelperText sx={{ mx: 0, mt: -0.5, mb: 1 }}>
                {watchError.message}
              </FormHelperText>
            )}
          </FormControl>
        )}
      </Stack>
    );
  };

  return (
    <AdditionalInfoSection
      title={t('QC_V2.MAIN.REMARK')}
      content={renderContent()}
    />
  );
}
