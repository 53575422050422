import { datadogRum } from '@datadog/browser-rum';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import { useTheme } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import { TFunction } from 'i18next';
import { useTranslation } from 'react-i18next';
import { buildImageUrl } from '@/helpers/image';
import { openQCView } from '@/helpers/routing';
import { QC_PRODUCT_STATE } from '@/helpers/states';
import { QcListType } from '@/types/QcInfo';
import { getUserNameFromEmail, getPriceFormatted } from '@/utils/helpers';
import { formatUtcTime } from '@/utils/time';

interface Data {
  photo: string;
  productId: number;
  brand: string;
  category: string;
  price: number;
  warehouse: string;
  qcStarted: string;
  currentStatus: string;
  onholdReason: string;
  updatedInfo: string;
  action: string;
}

interface HeadCell {
  disablePadding: boolean;
  id: keyof Data;
  label: string;
  numeric: boolean;
}

const getHeadCells = (
  t: TFunction<'translation', undefined>
): readonly HeadCell[] => {
  return [
    {
      id: 'photo',
      numeric: false,
      disablePadding: true,
      label: t('QC_V2.QC_LST.PHOTO'),
    },
    {
      id: 'productId',
      numeric: true,
      disablePadding: false,
      label: t('QC_V2.QC_LST.PRODUCT_ID'),
    },
    {
      id: 'brand',
      numeric: true,
      disablePadding: false,
      label: t('QC_V2.QC_LST.BRAND'),
    },
    {
      id: 'category',
      numeric: true,
      disablePadding: false,
      label: t('QC_V2.QC_LST.CATEGORY'),
    },
    {
      id: 'price',
      numeric: true,
      disablePadding: false,
      label: t('QC_V2.QC_LST.PRICE'),
    },
    {
      id: 'warehouse',
      numeric: true,
      disablePadding: false,
      label: t('QC_V2.QC_LST.QC_WAREHOUSE'),
    },
    {
      id: 'qcStarted',
      numeric: true,
      disablePadding: false,
      label: t('QC_V2.QC_LST.QC_STARTED'),
    },
    {
      id: 'currentStatus',
      numeric: true,
      disablePadding: false,
      label: t('QC_V2.QC_LST.CURRENT_STATUS'),
    },
    {
      id: 'onholdReason',
      numeric: true,
      disablePadding: false,
      label: t('QC_V2.QC_LST.REASON'),
    },
    {
      id: 'updatedInfo',
      numeric: true,
      disablePadding: false,
      label: t('QC_V2.QC_LST.UPDATED_BY'),
    },
    {
      id: 'action',
      numeric: true,
      disablePadding: false,
      label: t('QC_V2.MAIN.ACTIONS'),
    },
  ];
};

const getTableBody = ({
  rows,
  isQcListLoading,
  emptyRows,
  isFetchQcListError,
}: {
  rows: QcListType['data'];
  isQcListLoading: boolean;
  isFetchQcListError: boolean;
  emptyRows: number;
}) => {
  const { t } = useTranslation();
  const viewQcHistory = (productId: number) => {
    datadogRum.addAction(`View QC history ${productId}`, { id: productId });
    openQCView(productId.toString());
  };

  if (isQcListLoading || isFetchQcListError) {
    return (
      <TableRow style={{ height: 320 }}>
        <TableCell
          colSpan={11}
          sx={{
            textAlign: 'center',
            borderBottom: 'none',
          }}
        >
          {isQcListLoading ? 'Loading...' : 'Error while fetching data'}
        </TableCell>
      </TableRow>
    );
  }

  return (
    <>
      {rows.map((row) => {
        const { product } = row;

        return (
          <TableRow
            hover
            tabIndex={-1}
            key={row.uuid}
            sx={{ cursor: 'pointer' }}
          >
            <TableCell component="th" scope="row" padding="none">
              <img
                alt={product?.images[0]}
                src={buildImageUrl({ path: product?.images[0] })}
                style={{
                  width: '2rem',
                  height: '2rem',
                }}
              />
            </TableCell>
            <TableCell align="right">{product.id}</TableCell>
            <TableCell align="right">{product.brand}</TableCell>
            <TableCell align="right">{product.category}</TableCell>
            <TableCell align="right">
              {product.price ? getPriceFormatted(product.price) : '-'}
            </TableCell>
            <TableCell align="right">{product.warehouse.name}</TableCell>
            <TableCell align="right">
              {formatUtcTime(row.agentStartedAt) || '-'}
            </TableCell>
            <TableCell align="right">{row.stateName || '-'}</TableCell>
            <TableCell align="right">
              {row.state === QC_PRODUCT_STATE.HELD ? (
                <>
                  {row.standbyReason?.name}
                  <br />
                  {row.standbyComment || '-'}
                </>
              ) : (
                '-'
              )}
            </TableCell>
            <TableCell align="right">
              <span>{getUserNameFromEmail(row.agent?.email) || '-'}</span>
              <br />
              <span>{formatUtcTime(row.lastAgentActionDate) || '-'}</span>
            </TableCell>
            <TableCell align="right">
              <Button
                variant="text"
                onClick={() => viewQcHistory(row.product.id)}
              >
                {t('QC_V2.MAIN.VIEW')}
              </Button>
            </TableCell>
          </TableRow>
        );
      })}
      {emptyRows > 0 && (
        <TableRow
          style={{
            height: 53 * emptyRows,
          }}
        >
          <TableCell colSpan={10} />
        </TableRow>
      )}
    </>
  );
};

const QcListTable = ({
  isQcListLoading,
  isFetchQcListError,
  qcList,
  page,
  rowsPerPage,
  onChangePage,
  onChangeRowsPerPage,
}: {
  isQcListLoading: boolean;
  isFetchQcListError: boolean;
  page: number;
  rowsPerPage: number;
  onChangePage: (page: number) => void;
  onChangeRowsPerPage: (rowsPerPage: number) => void;
  qcList: QcListType;
}) => {
  const { t } = useTranslation();
  const theme = useTheme();

  const { data: rows, totalItems } = qcList || {
    data: [],
    totalItems: 0,
  };
  const handleChangePage = (_event: unknown, newPage: number) => {
    onChangePage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const itemsPerPage = parseInt(event.target.value, 10);
    onChangeRowsPerPage(itemsPerPage);
  };

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows = rowsPerPage > rows.length ? rowsPerPage - rows.length : 0;

  const headCells = getHeadCells(t);
  return (
    <Box sx={{ width: '100%', padding: '1rem' }}>
      <Typography variant="h6" mb="1rem">
        {t('QC_V2.QC_LST.RESULT')}
      </Typography>
      <Paper elevation={0} sx={{ width: '100%', mb: 2 }}>
        <TableContainer
          sx={{
            overflow: 'scroll',
            marginBottom: '2rem',
            color: theme.palette.text.primary,
            backgroundColor: theme.palette.background.default,
          }}
        >
          <Table
            stickyHeader
            sx={{ minWidth: 750 }}
            aria-labelledby="tableTitle"
            size="medium"
          >
            <TableHead>
              <TableRow>
                {headCells.map((headCell) => (
                  <TableCell
                    key={headCell.id}
                    align={headCell.numeric ? 'right' : 'left'}
                    padding={headCell.disablePadding ? 'none' : 'normal'}
                  >
                    {headCell.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {getTableBody({
                rows,
                isQcListLoading,
                emptyRows,
                isFetchQcListError,
              })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25, 50, 100]}
          style={{
            position: 'fixed',
            bottom: 0,
            right: 0,
            background: theme.palette.background.paper,
            width: '100%',
          }}
          component="div"
          count={totalItems}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
    </Box>
  );
};

QcListTable.displayName = 'QcListTable';

export default QcListTable;
