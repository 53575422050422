import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import { Box, Stack, Typography } from '@mui/material';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { CommentBadge } from '@/types/ProductInfo';
import { formatUtcTime } from '@/utils/time';

interface PublicCommentProps {
  description: string;
  createdAt?: string;
  showDot?: boolean;
  commentBadge?: CommentBadge;
  commentAfterSale?: boolean;
}

const BadgeName = ({ name }: { name: string }) => {
  return (
    <Box
      component="span"
      p="4px 8px"
      border={(theme) => `1px solid ${theme.palette.borderColor.light}`}
      borderRadius={5}
      color={(theme) => theme.palette.primary.main}
      sx={(theme) => ({
        ':hover': {
          borderColor: theme.palette.borderColor.main,
          backgroundColor: 'transparent',
        },
      })}
    >
      {name}
    </Box>
  );
};

const PublicComment = ({
  description,
  createdAt,
  showDot,
  commentBadge,
  commentAfterSale,
}: PublicCommentProps) => {
  const { t } = useTranslation();

  const badgeName = useMemo(() => {
    switch (commentBadge) {
      case CommentBadge.Buyer:
        return t('QC_V2.MAIN.BUYER');
      case CommentBadge.Seller:
        return t('QC_V2.MAIN.SELLER');
      case CommentBadge.Other:
        return t('QC_V2.MAIN.OTHER');
      default:
        return 'N/A';
    }
  }, [commentBadge]);

  const afterSaleLabel = commentAfterSale ? t('QC_V2.MAIN.AFTER_SALE') : '';

  return (
    <Stack
      mr={0.5}
      mb={1}
      sx={(theme) => ({
        '& > p': { color: theme.palette.text.secondary },
      })}
    >
      <Typography
        variant="body1"
        sx={{ display: 'flex', alignItems: 'baseline', gap: 0.5 }}
      >
        {showDot && <FiberManualRecordIcon sx={{ fontSize: 7.5 }} />}
        {description}
      </Typography>

      <Typography
        fontStyle="italic"
        textAlign="right"
        sx={{ fontSize: '14px !important', mt: 1 }}
      >
        <BadgeName name={badgeName} />{' '}
        {`${createdAt ? `- ${formatUtcTime(new Date(createdAt))}` : ''} ${afterSaleLabel ? `- ${afterSaleLabel}` : ''}`}
      </Typography>
    </Stack>
  );
};

export default PublicComment;
