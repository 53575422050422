export const RoutesMapping = {
  HOME: '/',
  QC_TOOL: '/quality-control',
  QC_LIST: '/list-view',
  AGENT_PRODUCTIVITY: '/productivity',
};

export const openQCView = (productId: string) => {
  if (window) {
    window.open(
      `${RoutesMapping.QC_TOOL}/${productId}?mode=view`,
      '_blank',
      'noopener noreferrer'
    );
  }
};
