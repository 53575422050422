import { Stack, StackProps } from '@mui/material';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import { PropsWithChildren, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useQualityControlTool } from '@/context/Qualitycontrol.context';
import { toWeightUnit } from '@/helpers/unit';
import { useQcMode } from '@/hooks/use-qcMode';
import AdditionalItems from './AdditionalItems';
import AdditionalPhotos from './AdditionalPhotos';
import AgentInternalComment from './AgentInternalComment';
import AgentPublicComment from './AgentPublicComment';
import RemarkInfo from './RemarkInfo';
import DecimalField from '../Common/Field/DecimalField';
import { ErrorType } from '../QcPanel/QcPanel';
import { Theme } from '../Theme';

const WrappedBox = ({ sx, children }: PropsWithChildren & StackProps) => {
  return (
    <Stack
      gap={1.5}
      border={`1px solid ${Theme.palette.grey[400]}`}
      borderRadius={1}
      padding="8px 1rem 1rem"
      position="relative"
      sx={{ ...sx }}
    >
      {children}
    </Stack>
  );
};

interface AdditionalInfoProps {
  showHallMarksAndWeight: boolean;
  isWeightHallmarkRequired: boolean;
  showWatchFunctioned: boolean;
  errors?: ErrorType[];
}

export default function AdditionalInfo({
  showHallMarksAndWeight,
  isWeightHallmarkRequired,
  showWatchFunctioned,
  errors,
}: AdditionalInfoProps) {
  const { t } = useTranslation();

  const { weight, setWeight, hallmarks, setHallmarks } =
    useQualityControlTool();

  const { isViewMode } = useQcMode();

  const weightError = useMemo(() => {
    if (weight) return undefined;
    return errors && errors.find((e) => e.field === 'weight');
  }, [errors, weight]);

  const hallmarkError = useMemo(() => {
    if (hallmarks) return undefined;
    return errors && errors.find((e) => e.field === 'hallmarks');
  }, [errors, hallmarks]);

  return (
    <Stack gap={1}>
      <AdditionalItems isViewMode={isViewMode} />

      <RemarkInfo
        isViewMode={isViewMode}
        showWatchFunctioned={showWatchFunctioned}
        errors={errors}
      />

      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          flexWrap: 'nowrap',
          padding: '1rem',
          pt: 0,
        }}
      >
        {showHallMarksAndWeight && (
          <Stack mt={1.25} gap={1.5}>
            <DecimalField
              data-testid="weight_input_text"
              id="weight"
              value={weight}
              onChange={setWeight}
              label={toWeightUnit(t('QC_V2.DEFECT.WEIGHT'))}
              placeholder={t('QC_V2.MAIN.WEIGHT_PLACEHOLDER')}
              sx={{ mb: 2 }}
              required={isWeightHallmarkRequired}
              error={weightError?.field === 'weight'}
              helperText={weightError?.message}
              InputLabelProps={{
                shrink: true,
              }}
              disabled={isViewMode}
            />

            <TextField
              data-testid="hallmarks_input_text"
              id="hallmark"
              label={t('QC_V2.MAIN.HALLMARKS')}
              placeholder={t('QC_V2.MAIN.HALLMARK_PLACEHOLDER')}
              value={hallmarks}
              sx={{ mb: 2 }}
              required={isWeightHallmarkRequired}
              error={hallmarkError?.field === 'hallmarks'}
              helperText={hallmarkError?.message}
              InputLabelProps={{
                shrink: true,
              }}
              disabled={isViewMode}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                setHallmarks(event.target.value);
              }}
            />
          </Stack>
        )}

        <WrappedBox sx={{ mt: 1 }}>
          <AdditionalPhotos
            title={t('QC_V2.MAIN.QC_PUBLIC_PHOTOS')}
            type="public"
          />
          <AgentPublicComment />
        </WrappedBox>

        <WrappedBox
          sx={{
            mt: 3.25,
            background: (theme) => theme.palette.commentBoxColor.main,
          }}
        >
          <AdditionalPhotos
            title={t('QC_V2.MAIN.QC_INTERNAL_PHOTOS')}
            type="internal"
          />
          <AgentInternalComment />
        </WrappedBox>
      </Box>
    </Stack>
  );
}
