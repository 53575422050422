import { datadogRum } from '@datadog/browser-rum';
import SearchIcon from '@mui/icons-material/Search';
import { TextField, InputAdornment, IconButton, Box } from '@mui/material';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import SnackBarNotification from '@/components/Common/SnackBar/SnackBar';
import ScanItemModals from '@/components/ScanItemModals';
import { useAuthContext } from '@/context/Authentication.context';
import { ModalProvider } from '@/context/Modal.context';

import { openQCView, RoutesMapping } from '@/helpers/routing';
import {
  QC_PRODUCT_STATE,
  QC_DECISION_STATE,
  QC_PRODUCT_DONE_STATE,
} from '@/helpers/states';
import { useProduct } from '@/hooks/use-product';
import { SCAN_ALERT_TYPE, useScanAlert } from '@/hooks/use-scanAlert';
import { putQcState } from '@/services/qc.service';
import { QcInfoType } from '@/types/QcInfo';
import { QC_DECISION_STATE_TYPE } from '@/types/QcStatus';

const SEARCHING_ISSUES = [
  SCAN_ALERT_TYPE.NO_QC_FOUND,
  SCAN_ALERT_TYPE.NEED_AUTHENTICATION,
  SCAN_ALERT_TYPE.ITEM_TAKEN_BY_OTHERS,
  SCAN_ALERT_TYPE.QC_ALREADY_FINISHED,
];

export const InputSearch = () => {
  const { t } = useTranslation();

  const [productId, setProductId] = useState<string>('');
  const navigate = useNavigate();
  const {
    qcData,
    setQcData,
    setProductId: setProductIdContext,
    reset,
    count,
  } = useProduct();

  const { user: userInfo } = useAuthContext();

  const { agent: itemAgent } = qcData as QcInfoType;
  const { alertType } = useScanAlert();
  const { submitted: isFromQcSubmit } = qcData as QcInfoType;

  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (isFromQcSubmit) return;

    const { uuid, state: productState } = qcData as QcInfoType;

    // If item is pending control, let current agent takes the control
    if (uuid && productState === QC_PRODUCT_STATE.PENDING_CONTROL) {
      putQcState({
        uuid,
        state: QC_DECISION_STATE.CONTROL as QC_DECISION_STATE_TYPE,
      })
        .then(({ data }) => {
          setQcData(data);
          if (data.product?.id) {
            datadogRum.addAction(`Go to QC ${data.product.id}`, {
              id: data.product.id,
            });
            navigate(`${RoutesMapping.QC_TOOL}/${data.product.id}`);
          }
        })
        .catch((err) => {
          if (err.response?.data) {
            setQcData(err.response.data);
          }
        });
    }

    // If item is already in controll by current agent, go to the qc tool page
    if (
      productState === QC_PRODUCT_STATE.BEING_CONTROLLED &&
      itemAgent &&
      itemAgent?.email === userInfo.email &&
      productId
    ) {
      datadogRum.addAction(`Go to QC ${productId}`, { id: productId });
      navigate(`${RoutesMapping.QC_TOOL}/${productId}`);
    }

    // Product is done QC, open new tab to view QC result
    const isDoneQC = QC_PRODUCT_DONE_STATE.includes(productState);

    if (isDoneQC) {
      datadogRum.addAction(`View QC result ${productId}`, { id: productId });
      openQCView(productId);
    }
  }, [(qcData as QcInfoType)?.uuid, itemAgent]);

  useEffect(() => {
    if (alertType && SEARCHING_ISSUES.includes(alertType)) {
      setProductId('');
      if (inputRef.current) inputRef.current.focus();
    }
  }, [alertType]);

  const handleProductIdChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    // Remove all non-digit characters
    const numericValue = e.target.value.replace(/\D/g, '');
    setProductId(numericValue);
    reset();
  };

  const handleSearchProduct = async (
    e: React.KeyboardEvent<HTMLInputElement>
  ) => {
    if (e.key === 'Enter') {
      setProductIdContext(productId);
      datadogRum.addAction(`Scan product ${productId}`, { id: productId });
    }
  };

  return (
    <ModalProvider>
      <Box
        className="qc-search--container"
        sx={(theme) => ({
          background: theme.palette.background.default,
        })}
      >
        <Grid
          container
          direction="column"
          alignItems="center"
          justifyContent="center"
          sx={{ height: '100%', paddingBottom: '3rem' }}
        >
          {alertType && (
            <SnackBarNotification
              key={`${productId}_${count}`}
              open={!!alertType}
              productId={productId}
              alertType={alertType}
            />
          )}
          <Typography variant="h5" gutterBottom sx={{ marginBottom: '1.5rem' }}>
            {t('QC_V2.SCAN.TITLE')}
          </Typography>
          <TextField
            inputRef={inputRef}
            data-testid="product_id_input"
            autoFocus
            variant="outlined"
            placeholder={t('QC_V2.SCAN.INPUT_PLACEHOLDER')}
            sx={{ width: '350px', paddingLeft: 0 }}
            value={productId}
            onChange={handleProductIdChange}
            onKeyDown={handleSearchProduct}
            InputProps={{
              sx: { paddingLeft: 0 },
              startAdornment: (
                <InputAdornment position="end">
                  <IconButton>
                    <SearchIcon />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </Grid>
      </Box>
      {!isFromQcSubmit && <ScanItemModals />}
    </ModalProvider>
  );
};
