import TextField from '@mui/material/TextField';
import { useTranslation } from 'react-i18next';
import { useQualityControlTool } from '@/context/Qualitycontrol.context';
import { useQcMode } from '@/hooks/use-qcMode';

const MAX_LENGTH = 300;

const AgentPublicComment = () => {
  const { t } = useTranslation();

  const { agentPublicComment, setAgentPublicComment } = useQualityControlTool();

  const { isViewMode } = useQcMode();

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value.substring(0, MAX_LENGTH);
    setAgentPublicComment(value);
  };

  return (
    <TextField
      data-testid="public_comment_input_text"
      id="agentPublicComment"
      label={t('QC_V2.MAIN.QC_PUBLIC_COMMENT')}
      placeholder={t('QC_V2.MAIN.COMMENT_PLACEHOLDER')}
      value={agentPublicComment}
      disabled={isViewMode}
      onChange={handleChange}
      multiline
      InputLabelProps={{
        shrink: true,
      }}
    />
  );
};

export default AgentPublicComment;
