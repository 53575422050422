import { datadogRum } from '@datadog/browser-rum';
import { Box, Stack, Typography } from '@mui/material';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import CloseButton from '../Modal/CloseButton';
import QcModal from '../Modal/Modal';

interface CommentModalProps {
  open: boolean;
  onClose: () => void;
  description: string;
  internalComment: string | null;
  publicComments: JSX.Element[];
}

const CommentModal = ({
  open,
  onClose,
  description,
  internalComment,
  publicComments,
}: CommentModalProps) => {
  const { t } = useTranslation();

  useEffect(() => {
    if (open) {
      datadogRum.addAction('Open modal comments modal');
    }
  }, [open]);

  return (
    <QcModal
      open={open}
      id="comment-modal"
      maxWidth="lg"
      dialogProps={{
        onClose,
      }}
      content={
        <>
          <CloseButton onClick={onClose} />

          <Box display="flex" mt={2.5} gap={5}>
            <Stack flex="1 1 auto" gap={3.5} maxWidth="50%">
              <Stack>
                <Typography variant="h6" mb={0.5}>
                  {t('QC_V2.MAIN.DESCRIPTION')}
                </Typography>
                <Typography
                  variant="body1"
                  sx={{
                    wordBreak: 'break-word',
                    whiteSpace: 'pre-line',
                  }}
                >
                  {description || '-'}
                </Typography>
              </Stack>

              <Stack>
                <Typography variant="h6" mb={0.5}>
                  {t('QC_V2.MAIN.COMMENT')}
                </Typography>
                <Box
                  sx={{
                    '& p,span': {
                      color: (theme) => theme.palette.textBlackColor.main,
                    },
                  }}
                >
                  {publicComments.length ? (
                    <Stack gap={1}>{publicComments.map((c) => c)}</Stack>
                  ) : (
                    '-'
                  )}
                </Box>
              </Stack>
            </Stack>

            <Stack flex="1 1 auto" maxWidth="50%">
              <Typography variant="h6">{t('QC_V2.MAIN.AUTH_CMT')}</Typography>
              <Typography
                variant="body1"
                sx={{
                  wordBreak: 'break-word',
                  whiteSpace: 'pre-line',
                }}
              >
                {internalComment || '-'}
              </Typography>
            </Stack>
          </Box>
        </>
      }
    />
  );
};

export default CommentModal;
