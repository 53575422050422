import styled from '@emotion/styled';
import { Box, Grid } from '@mui/material';
import { useCallback, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';

import { GeneralInfo } from '@/components/ProductInfo/GeneralInfo';
import { QcPanel } from '@/components/QcPanel/QcPanel';
import QcPanelModal from '@/components/QcPanelModal/QcPanelModal';
import { useAuthContext } from '@/context/Authentication.context';
import { ModalProvider } from '@/context/Modal.context';
import {
  useQualityControlTool,
  SetDefectPayloadType,
} from '@/context/Qualitycontrol.context';
import { RoutesMapping } from '@/helpers/routing';
import { QC_PRODUCT_STATE } from '@/helpers/states';
import { UploadedFileType } from '@/hooks/defect-details/use-upload-additional-photos';
import { useProduct } from '@/hooks/use-product';
import { useQcMode } from '@/hooks/use-qcMode';
import { QcInfoRecordType, QcInfoType } from '@/types/QcInfo';
import './index.scss';

const ProductInfoGrid = styled(Grid)`
  &.MuiGrid-item {
    padding: 2rem 1rem 2rem 2rem;
    overflow-x: hidden;
  }
`;

const QcPanelGrid = styled(Grid)`
  &.MuiGrid-item {
    padding-left: 0;
  }
`;

export const QualityControlTool = () => {
  const { id } = useParams();
  const { isViewMode } = useQcMode();
  const { qcData, productId, setProductId, reset } = useProduct();
  const { user: userInfo } = useAuthContext();
  const navigate = useNavigate();
  const qcInfo = qcData as QcInfoType;
  const {
    setAdditionalItems,
    setDefect,
    setHallmarks,
    setWeight,
    setRefurbishment,
    setWorkingWatch,
    setAgentComment,
    setAgentPublicComment,
    updateQCPhotos,
  } = useQualityControlTool();

  useEffect(() => {
    // If page is in view mode, allow users to view the page content
    if (isViewMode) {
      return;
    }

    const { product, record } = qcInfo || {};

    if (product) {
      setHallmarks(product.hallmarks || '');
      setWeight(String(product.weight || ''));
    }

    // In case ON-HOLD, should show last uploaded photos
    if (
      record?.publicAdditionalPhotoUrls?.length ||
      record?.internalAdditionalPhotoUrls?.length
    ) {
      addQCPhotos(record);
    }

    // Check when qcInfo is loaded
    // Redirect to home if the product is controlled by others
    const qcAgentEmail = qcInfo?.agent?.email;

    if (qcInfo && Object.keys(qcInfo).length) {
      if (
        qcInfo.state !== QC_PRODUCT_STATE.BEING_CONTROLLED ||
        (qcAgentEmail && qcAgentEmail !== userInfo.email)
      ) {
        setProductId('');
        reset();
        navigate(RoutesMapping.HOME, { replace: true });
      }
    }
  }, [qcData]);

  useEffect(() => {
    if (id && id !== productId) {
      setProductId(id);
    }
  }, [productId, id, setProductId]);

  useEffect(() => {
    // For qc result view page, set qc info to the fields
    if (qcInfo.record) {
      const { record } = qcInfo;

      const recordsDetails = (record.checkpointRecords || []).reduce(
        (acc, checkpointRecords) => {
          const defectRecords = checkpointRecords.defectRecords || [];

          const recordsInfo = defectRecords.map((item) => {
            return {
              ...item,
              checkPointId: checkpointRecords.checkpoint.id,
            };
          });

          return acc.concat(...recordsInfo);
        },
        [] as SetDefectPayloadType['data']
      );

      setDefect({
        data: recordsDetails || [],
      });
      setAdditionalItems(
        (record.additionalItemRecords || []).map((item) => item.additionalItem)
      );

      if (isViewMode) {
        setHallmarks(record.hallmarks || '');
        setWeight(String(record.weight || ''));
      }

      setRefurbishment(record.isRefurbished);
      setWorkingWatch(record.isFunctioned);
      setAgentComment(record.agentComment || '');
      setAgentPublicComment(record.agentPublicComment || '');

      addQCPhotos(record);
    }
  }, [qcInfo, isViewMode]);

  const addQCPhotos = useCallback(
    (record: QcInfoRecordType) => {
      const {
        internalAdditionalPhotoUrls,
        internalAdditionalPhotos,
        publicAdditionalPhotoUrls,
        publicAdditionalPhotos,
      } = record || {};

      if (publicAdditionalPhotoUrls) {
        const publicPhotos: UploadedFileType[] = publicAdditionalPhotoUrls.map(
          (path, idx) => ({
            id: idx.toString(),
            imageUrl: path,
            imagePath: publicAdditionalPhotos?.[idx] || '',
          })
        );
        updateQCPhotos(publicPhotos, 'public');
      }

      if (internalAdditionalPhotoUrls) {
        const internalPhotos: UploadedFileType[] =
          internalAdditionalPhotoUrls.map((path, idx) => ({
            id: idx.toString(),
            imageUrl: path,
            imagePath: internalAdditionalPhotos?.[idx] || '',
          }));
        updateQCPhotos(internalPhotos, 'internal');
      }
    },
    [updateQCPhotos]
  );

  return (
    <ModalProvider>
      <Grid container spacing={2} className="QualityControl_tool">
        <ProductInfoGrid
          item
          md={4}
          className="QualityControl_tool__productInfo"
          sx={(theme) => ({
            height: 'calc(100vh - 2.75rem)',
            overflow: 'scroll',
            scrollbarWidth: 'thin',
            backgroundColor: theme.palette.background.paper,
          })}
        >
          <GeneralInfo />
          {/* This box is for the space of the icon menu */}
          <Box sx={{ height: 45 }} />
        </ProductInfoGrid>

        <QcPanelGrid item md={8}>
          <QcPanel />
        </QcPanelGrid>
      </Grid>
      <QcPanelModal />
    </ModalProvider>
  );
};
