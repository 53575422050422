import { Grid, Stack, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import AdditionalInfo from '@/components/AdditionalInfo/AdditionalInfo';
import QcSubmitButtons from '@/components/QcSubmitButtons/QcSubmitButtons';
import ScrollableTabs from '@/components/ScrollableTabs/ScrollableTabs';
import { useQualityControlTool } from '@/context/Qualitycontrol.context';
import {
  getStatusMapping,
  QC_REQUIRED_JEWELRY_MATERIALS,
} from '@/helpers/states';
import { useProduct } from '@/hooks/use-product';
import { useQcMode } from '@/hooks/use-qcMode';
import { PRODUCT_TYPES } from '@/types/ProductInfo';

export type ErrorType = {
  field: 'weight' | 'hallmarks' | 'watch';
  message: string;
};

export const QcPanel = () => {
  const { t } = useTranslation();

  const { defects } = useQualityControlTool();
  const isProhibited = defects.find((defect) => defect.isProhibited);
  const { isViewMode } = useQcMode();

  const { product, state } = useProduct();

  const [errors, setErrors] = useState<ErrorType[]>();

  const isJewelry =
    product &&
    product.type?.toLowerCase() === PRODUCT_TYPES.JEWELRY.toLowerCase();

  const isWatch =
    product &&
    product.type?.toLowerCase() === PRODUCT_TYPES.WATCH.toLowerCase();

  const isWeightHallmarkRequired =
    (isJewelry || isWatch) &&
    QC_REQUIRED_JEWELRY_MATERIALS.includes(product?.material?.toLowerCase());

  const statusMapped = getStatusMapping(t);

  return (
    <>
      <div>
        <Stack
          flexDirection="row"
          gap={1}
          alignItems="center"
          sx={{ borderBottom: '1px solid #e0e0e0' }}
        >
          <Typography
            variant="h5"
            sx={{
              padding: '1rem 0.5rem 1rem 2rem',
              fontSize: '1.25rem',
              fontWeight: '700',
            }}
            data-testid="qc_1st"
          >
            {t('QC_V2.MAIN.1ST_QC_LABEL')}
          </Typography>

          {!!statusMapped[state] && (
            <Typography
              data-testid="qc_status"
              variant="body2"
              sx={{
                padding: '4px 12px',
                borderRadius: '0.25rem',
                color: statusMapped[state].color,
                backgroundColor: statusMapped[state].background || 'white',
                border: `1px solid ${statusMapped[state].color}`,
                fontSize: '0.875rem',
                fontWeight: '500',
              }}
            >
              {statusMapped[state].label.toUpperCase()}
            </Typography>
          )}

          {isProhibited && (
            <Typography
              data-testid="qc_status_prohibition"
              variant="body2"
              sx={{
                padding: '4px 12px',
                backgroundColor: 'white',
                borderRadius: '0.25rem',
                color: '#F44336',
                border: '1px solid #F44336',
              }}
            >
              {t('QC_V2.MAIN.KO_PROHIBITION')?.toUpperCase()}
            </Typography>
          )}
        </Stack>
      </div>
      <Box display="flex" flexDirection="column">
        <Grid container mb={3}>
          <Grid item md={8}>
            <ScrollableTabs />
          </Grid>
          <Grid
            item
            md={4}
            sx={{
              mt: 3,
              height: 'calc(100vh - 16rem)',
              overflowY: 'scroll',
              scrollbarWidth: 'thin',
            }}
          >
            <AdditionalInfo
              showHallMarksAndWeight={isJewelry || isWatch}
              showWatchFunctioned={isWatch}
              isWeightHallmarkRequired={isWeightHallmarkRequired}
              errors={errors}
            />
          </Grid>
        </Grid>
        {!isViewMode && (
          <QcSubmitButtons
            isWeightHallmarkRequired={isWeightHallmarkRequired}
            isWatchFunctionedRequired={isWatch}
            setErrors={setErrors}
          />
        )}
      </Box>
    </>
  );
};
