import { Stack, TextField, TextFieldProps } from '@mui/material';
import {
  Controller,
  FieldPath,
  FieldValues,
  useController,
} from 'react-hook-form';
import { Theme } from '@/components/Theme';
import { FormField } from '@/types/interfaces/form';
import ErrorMessage from './ErrorMessage';

interface TextFieldFormProps<
  TFieldValues extends FieldValues,
  TName extends FieldPath<TFieldValues>,
> extends FormField<TFieldValues, TName> {
  textFieldProps?: TextFieldProps;
}

const FormTextField = <
  TFieldValues extends FieldValues,
  TName extends FieldPath<TFieldValues>,
>({
  name,
  label,
  control,
  rules,
  textFieldProps,
}: TextFieldFormProps<TFieldValues, TName>) => {
  const {
    fieldState: { error },
  } = useController({ control, name });

  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      render={({ field: { ref, ...field } }) => (
        <Stack>
          <TextField
            label={rules?.required ? `${label} *` : label}
            {...field}
            fullWidth
            inputRef={ref}
            {...textFieldProps}
            sx={{
              ...(textFieldProps?.sx || {}),
              '& .MuiOutlinedInput-notchedOutline': {
                borderColor: error ? Theme.palette.error.main : 'inherit',
              },
            }}
          />
          <ErrorMessage error={error} />
        </Stack>
      )}
    />
  );
};

export default FormTextField;
