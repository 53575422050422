import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import { Stack, Typography } from '@mui/material';
import { TFunction } from 'i18next';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import {
  useAuthContext,
  AuthContextType,
} from '@/context/Authentication.context';
import { NoProductQcType } from '@/context/Product.context';
import { RoutesMapping } from '@/helpers/routing';
import { QC_DECISION_STATE, QC_PRODUCT_STATE } from '@/helpers/states';
import {
  QcInfoType,
  QcErrorNeedAuth,
  QcErrorNeedFinishQc,
} from '@/types/QcInfo';
import { useProduct } from './use-product';
import useUpdateQcState from './use-update-qc-state';

export const SCAN_ALERT_TYPE = {
  NO_QC_FOUND: 'NO_QC_FOUND',
  NEED_AUTHENTICATION: 'NEED_AUTHENTICATION',
  QC_SUBMITTED: 'QC_SUBMITTED',
  ITEM_TAKEN_BY_OTHERS: 'ITEM_TAKEN_BY_OTHERS',
  QC_ALREADY_FINISHED: 'QC_ALREADY_FINISHED',
};

export type AlertMessageType = {
  title: string;
  icon: JSX.Element;
  message: string | JSX.Element;
  color: string;
  testid?: string;
};

export const getAlertInfo = ({
  qcData,
  userInfo,
  productId,
  takeControl,
  t,
}: {
  qcData: object;
  userInfo: AuthContextType['user'];
  productId: string;
  takeControl: ({ uuid }: { uuid: string }) => void;
  t: TFunction<'translation', undefined>;
}): {
  alertType?:
    | (typeof SCAN_ALERT_TYPE)[keyof typeof SCAN_ALERT_TYPE]
    | undefined;
  alertMessage?: AlertMessageType | undefined;
} => {
  if (!qcData || !Object.keys(qcData).length) {
    return {};
  }

  if ((qcData as NoProductQcType).noProductQc) {
    return {
      alertType: SCAN_ALERT_TYPE.NO_QC_FOUND,
      alertMessage: {
        title: t('QC_V2.SCAN.ERROR_MESSAGE'),
        icon: <WarningAmberIcon />,
        message: t('QC_V2.SCAN.MSG_NOT_FOUND', { productId }),
        color: 'warning',
        testid: 'scan_alert_error',
      },
    };
  }

  if ((qcData as QcErrorNeedAuth).needAuthentication) {
    return {
      alertType: SCAN_ALERT_TYPE.NEED_AUTHENTICATION,
      alertMessage: {
        title: t('QC_V2.SCAN.ERROR_MESSAGE'),
        icon: <WarningAmberIcon />,
        message: t('QC_V2.SCAN.MSG_NEED_AUTHEN', { productId }),
        color: 'warning',
        testid: 'scan_alert_error',
      },
    };
  }

  if ((qcData as QcErrorNeedFinishQc)['hydra:description']) {
    const {
      productId: productIdInQc,
      referenceErrorCode,
      'hydra:description': errorDescp,
    } = qcData as QcErrorNeedFinishQc;

    if ((qcData as QcErrorNeedFinishQc).referenceErrorCode) {
      if (
        referenceErrorCode === 'qc.agent_already_controlling_another_instance'
      ) {
        return {
          alertType: SCAN_ALERT_TYPE.NEED_AUTHENTICATION,
          alertMessage: {
            title: t('QC_V2.SCAN.ERROR_MESSAGE'),
            icon: <WarningAmberIcon />,
            message: t('QC_V2.SCAN.MSG_FINISH_QC_FOR_PRODUCT', {
              productId: productIdInQc,
            }),
            color: 'warning',
            testid: 'scan_alert_error',
          },
        };
      } else {
        return {
          alertType: SCAN_ALERT_TYPE.NEED_AUTHENTICATION,
          alertMessage: {
            title: t('QC_V2.SCAN.ERROR_MESSAGE'),
            icon: <WarningAmberIcon />,
            message: errorDescp,
            color: 'warning',
            testid: 'scan_alert_error',
          },
        };
      }
    } else {
      return {
        alertType: SCAN_ALERT_TYPE.NEED_AUTHENTICATION,
        alertMessage: {
          title: t('QC_V2.SCAN.ERROR_MESSAGE'),
          icon: <WarningAmberIcon />,
          message: errorDescp,
          color: 'warning',
          testid: 'scan_alert_error',
        },
      };
    }
  }

  const qcInfo = qcData as QcInfoType;
  const { agent, stateName, state, uuid } = qcInfo;

  if ((qcData as QcInfoType).submitted) {
    return {
      alertType: SCAN_ALERT_TYPE.QC_SUBMITTED,
      alertMessage: {
        title: t('QC_V2.SCAN.SUBMIT_SUCCESSFUL'),
        icon: <CheckCircleOutlineIcon />,
        message: t('QC_V2.SCAN.MSG_RESULT_SUCCESS', { itemQcState: stateName }),
        color: 'success',
        testid: 'scan_alert_success',
      },
    };
  }

  if (
    state === QC_PRODUCT_STATE.BEING_CONTROLLED &&
    agent?.email &&
    agent.email !== userInfo?.email
  ) {
    return {
      alertType: SCAN_ALERT_TYPE.ITEM_TAKEN_BY_OTHERS,
      alertMessage: {
        title: t('QC_V2.SCAN.ERROR_MESSAGE'),
        icon: <WarningAmberIcon />,
        message: (
          <Stack gap={0.5}>
            <Typography variant="body2">
              {t('QC_V2.SCAN.MSG_PRODUCT_TAKEN', {
                productId,
                agentEmail: agent.email,
              })}
            </Typography>
            <Typography
              variant="body2"
              sx={{
                textDecoration: 'underline',
                ':hover': { cursor: 'pointer' },
              }}
              onClick={() => takeControl({ uuid })}
            >
              {t('QC_V2.SCAN.TAKE_OVER')}
            </Typography>
          </Stack>
        ),
        color: 'warning',
        testid: 'scan_alert_error',
      },
    };
  }

  if ([QC_PRODUCT_STATE.QC_EXIT].includes(state)) {
    return {
      alertType: SCAN_ALERT_TYPE.QC_ALREADY_FINISHED,
      alertMessage: {
        title: t('QC_V2.SCAN.ERROR_MESSAGE'),
        icon: <WarningAmberIcon />,
        message: t('QC_V2.SCAN.MSG_PRODUCT_ON_HOLD', { productId, stateName }),
        color: 'warning',
        testid: 'scan_alert_error',
      },
    };
  }

  return {};
};

export const useScanAlert = (): {
  alertType: (typeof SCAN_ALERT_TYPE)[keyof typeof SCAN_ALERT_TYPE] | undefined;
  alertMessage: AlertMessageType | undefined;
  isLoading: boolean;
} => {
  const { t } = useTranslation();

  const { qcData, productId, setQcData } = useProduct();
  const { user: userInfo } = useAuthContext();

  const navigate = useNavigate();

  const { mutate: takeControl, isPending } = useUpdateQcState();

  const handleTakeControl = ({ uuid }: { uuid: string }) => {
    takeControl(
      { uuid, state: QC_DECISION_STATE.TAKE_OVER_CONTROL },
      {
        onSuccess: ({ data }) => {
          if (data) {
            setQcData(data);
            navigate(`${RoutesMapping.QC_TOOL}/${productId}`);
          }
        },
      }
    );
  };

  const { alertType, alertMessage } = getAlertInfo({
    qcData,
    userInfo,
    productId,
    takeControl: handleTakeControl,
    t,
  });

  return {
    alertType,
    alertMessage,
    isLoading: isPending,
  };
};
